@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://at.alicdn.com/t/c/font_4660155_2cv79dwawya.css');

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0px 1000px #edeeef inset !important;
  -webkit-box-shadow: 0 0 0px 1000px #edeeef inset !important;
  -webkit-text-fill-color: #2f353c !important;
  caret-color: #2f353c !important;
}

input[type='search']::-webkit-search-cancel-button {
  cursor: pointer;
  margin-left: 6px;
}

svg {
  overflow: visible !important;
}
svg:not(:root) {
  overflow: visible !important; /* 强制显示溢出的部分 */
}

a {
  text-decoration: none;
  color: inherit;
}

:root {
  --scrollbar-thumb-color: #ccc;
  --scrollbar-track-color: #f1f1f1;
  --scrollbar-width: 2px;
  --scrollbar-height: 2px;
}

[data-theme='dark'] {
  --scrollbar-thumb-color: #555;
  --scrollbar-track-color: #333;
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: var(--scrollbar-height);
}

/* WebKit 浏览器 */
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
}

::-webkit-scrollbar-button {
  display: none; /* 隐藏滚动条箭头 */
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
}
